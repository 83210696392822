
import { defineComponent } from 'vue'
import { useBottomBar } from '@/compositions/bottomBar'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: { TmButton },
  setup() {
    const { changesCounter, getDiscardFunc } = useBottomBar()

    return {
      changesCounter,
      getDiscardFunc,
    }
  },
})
