
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useDialpad } from '@/compositions/chats/chats'

export default defineComponent({
  components: {
    TmButton,
  },
  setup() {
    const { getCurrentFilter, setCurrentFilter } = useDialpad()
    const items = [
      {
        name: 'all',
        label: 'All calls',
      },
      {
        name: 'outbound',
        label: 'My calls',
      },
      {
        name: 'missed',
        label: 'Missed',
      },
    ]

    return {
      items,
      getCurrentFilter,
      setCurrentFilter,
    }
  },
})
