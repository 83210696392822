
import { defineComponent, ref, computed } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import HeaderNotificationsList from '@/components/layout/header/headerNotifications/HeaderNotificationsList.vue'
import InfoBlock from '@/components/layout/header/headerNotifications/InfoBlock.vue'
import { notificationsList } from '@/definitions/notifications/data'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    TmButton,
    TmDropdown,
    HeaderNotificationsList,
    InfoBlock,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const unreadValue = ref(false)
    const notificationsValue = ref(notificationsList)

    const markAllRead = () => {
      notificationsValue.value.forEach(day => {
        day.notifications.forEach(notification => {
          notification.unread = false
        })
      })
    }

    const unreadMessages = computed(() => {
      let unreadCount = 0
      notificationsList.value.forEach(day => {
        day.notifications.forEach(notification => {
          if (notification.unread) {
            unreadCount++
          }
        })
      })
      return unreadCount
    })

    return {
      isEmptyMode,
      unreadValue,
      notificationsValue,
      markAllRead,
      unreadMessages,
    }
  },
})
