
import { onMounted, onUnmounted, computed, defineComponent, ref, watch, nextTick, reactive } from 'vue'
import type { PropType } from 'vue'
import DropUpHeader from '@/components/layout/dropUp/DropUpHeader.vue'
import DropUpContentEditor from '@/components/layout/dropUp/DropUpContentEditor.vue'
import { useModals } from '@/compositions/modals'
import type { DropUpValueType, DropUpName } from '@/definitions/_layout/dropUps/types'
import { useDropUps } from '@/compositions/dropups'
import { getAssociateOptions } from '@/mock/associateCategories'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import { senderSettingsOptions } from '@/definitions/_general/_data/optionsList'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmChips from '@/components/shared/TmChips.vue'
import MessageEditorTags from '@/components/shared/chat/messageEditor/buttons/MessageEditorTags.vue'

export default defineComponent({
  components: {
    MessageEditorTags,
    TmChips,
    TmTooltip,
    TmDropdownItem,
    TmDropdown,
    TmButton,
    DropUpHeader,
    DropUpContentEditor,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Object as PropType<DropUpValueType>,
      required: true,
    },
    dropUpName: {
      type: String as PropType<DropUpName>,
      required: true,
    },
    isModalView: {
      type: Boolean,
    },
    isCampaign: {
      type: Boolean,
    },
    totalRecipients: {
      type: Array,
    },
  },
  setup(props) {
    const associateToRef = ref()
    const associateCcRef = ref()
    const associateBccRef = ref()

    const subfields = reactive({
      isCc: false,
      isBcc: false,
    })

    const isFieldsFocused = ref(false)

    const { openModal } = useModals()
    const isTextMessage = ref(props.dropUpName === 'textMessage')

    const { getDropUpData } = useDropUps()
    const fromOptions = ref(getDropUpData(props.dropUpName).fromOptions)
    const recipientsOptions = getAssociateOptions(['contacts', 'lists', 'segments'])
    const recipientsOptionsTransformed = ref(
      recipientsOptions
        .map((item: any) => {
          return {
            ...item,
            label: item?.name,
            icon: item?.icon === 'person' ? '' : item?.icon,
          }
        })
    )

    const isEmail = computed(() => props.dropUpName === 'email')
    const isSmallFields = computed(() => subfields.isCc || subfields.isBcc)

    const addContacts = (num: number) => {
      props.modelValue.to = [
        ...recipientsOptionsTransformed.value.filter(item => !item.notForSearch).slice(0, num),
      ]
    }

    const blurFields = () => {
      subfields.isCc = false
      subfields.isBcc = false
    }
    const focusFields = (cc?: boolean, bcc?: boolean) => {
      isFieldsFocused.value = true
      subfields.isCc = cc || !!props.modelValue.cc?.length
      subfields.isBcc = bcc || !!props.modelValue.bcc?.length
    }

    const checkClick = (e: any) => {
      const elem = e.srcElement.closest(`.drop-up-content-${props.dropUpName} .drop-up-content--blur-fields`)
      isFieldsFocused.value = !elem
      if (!isFieldsFocused.value) blurFields()
    }

    onMounted(() => {
      document.querySelector('.drop-up-content-' + props.dropUpName)?.addEventListener('click', checkClick)
    })

    onUnmounted(() => {
      document.querySelector('.drop-up-content-' + props.dropUpName)?.removeEventListener('click', checkClick)
    })

    const setFocusSubfield = async () => {
      await nextTick()
      if (isFieldsFocused.value) {
        if (subfields.isBcc) {
          associateBccRef.value.focus()
        } else if (subfields.isCc) {
          associateCcRef.value.focus()
        } else {
          associateToRef.value.focus()
        }
      }
    }

    watch(isFieldsFocused, () => {
      setFocusSubfield()
    })

    return {
      isTextMessage,
      fromOptions,
      recipientsOptions,
      recipientsOptionsTransformed,
      senderSettingsOptions,
      openModal,
      addContacts,
      blurFields,
      focusFields,
      isFieldsFocused,
      checkClick,
      associateToRef,
      associateCcRef,
      associateBccRef,
      subfields,
      isSmallFields,
      isEmail,
    }
  },
})
