
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import useSmsSurvey from '@/compositions/services/useSmsSurvey'
import TmAutoSizePanel from '@/components/shared/autoSize/TmAutoSizePanel.vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'
import { tmAutoSizeIgnoreClass } from '@/definitions/shared/autoSize/data'

export default defineComponent({
  name: 'SurveysHeaderActions',
  components: { TmAutoSizePanel, TmAutoSizePanelItemButton, TmButton },
  setup() {
    const { openDuplicateSurveyModal, openDeleteSurveyModal, openResetSurveyModal } = useSmsSurvey()

    return {
      openDuplicateSurveyModal,
      openDeleteSurveyModal,
      tmAutoSizeIgnoreClass,
      openResetSurveyModal,
    }
  },
})
