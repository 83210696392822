
import { defineComponent } from 'vue'
import SurveysHeaderMainInfo from '@/components/layout/header/surveys/SurveysHeaderMainInfo.vue'
import SurveysHeaderActions from '@/components/layout/header/surveys/SurveysHeaderActions.vue'

export default defineComponent({
  name: 'SurveysHeader',
  components: {
    SurveysHeaderActions,
    SurveysHeaderMainInfo,
  },
})
