
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import MainSidebar from '@/components/layout/mainSidebar/MainSidebar.vue'
import { useMobileNavigation } from '@/compositions/mobileNavigation'
import type { RouteRecordRaw } from 'vue-router'

export default defineComponent({
  components: {
    MainSidebar,
  },
  props: {
    menu: {
      type: Object as PropType<RouteRecordRaw>,
      required: true,
    },
  },
  setup() {
    const { getMenuState } = useMobileNavigation()

    return {
      getMenuState,
    }
  },
})
