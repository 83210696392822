
import { computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import BaseContainer from '@/components/containers/BaseContainer.vue'
import SurveysContainer from '@/components/containers/SurveysContainer.vue'
import DropUpsContainer from '@/components/containers/DropUpsContainer.vue'

export default defineComponent({
  name: 'RootContainer',
  components: { DropUpsContainer, SurveysContainer, BaseContainer },
  setup() {
    const route = useRoute()
    const containerName = computed(() => route.meta.containerName || 'BaseContainer')
    const showSidebarOnHover = computed(() => route.meta.showSidebarOnHover)
    const sidebarCollapsed = computed(() => route.meta.sidebarCollapsed)
    const hideBalance = computed(() => route.meta.hideBalance)

    return {
      route,
      hideBalance,
      containerName,
      showSidebarOnHover,
      sidebarCollapsed,
    }
  },
})
