
import { defineComponent } from 'vue'
import TmEmoji from '@/components/shared/TmEmoji.vue'

export default defineComponent({
  components: {
    TmEmoji,
  },
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
    },
  },
})
