import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00fd1f31"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "surveys-container" }
const _hoisted_2 = { class: "surveys-container__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_sidebar = _resolveComponent("main-sidebar")!
  const _component_surveys_header = _resolveComponent("surveys-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_surveys_footer = _resolveComponent("surveys-footer")!
  const _component_tm_content = _resolveComponent("tm-content")!
  const _component_tm_modal_container = _resolveComponent("tm-modal-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isMdMax)
      ? (_openBlock(), _createBlock(_component_main_sidebar, {
          key: 0,
          menu: _ctx.routes,
          opened: !_ctx.isXlMax
        }, null, 8, ["menu", "opened"]))
      : _createCommentVNode("", true),
    _createVNode(_component_tm_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_surveys_header),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_view)
        ]),
        _createVNode(_component_surveys_footer)
      ]),
      _: 1
    }),
    _createVNode(_component_tm_modal_container)
  ]))
}