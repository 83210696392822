import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chats_active_call_widget = _resolveComponent("chats-active-call-widget")!
  const _component_header_profile_balance = _resolveComponent("header-profile-balance")!
  const _component_header_notifications = _resolveComponent("header-notifications")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_header_profile = _resolveComponent("header-profile")!
  const _component_tm_header = _resolveComponent("tm-header")!

  return (_openBlock(), _createBlock(_component_tm_header, null, {
    "header-right": _withCtx(() => [
      (!_ctx.showCall && _ctx.activeCall)
        ? (_openBlock(), _createBlock(_component_chats_active_call_widget, {
            key: 0,
            class: "mr-5"
          }))
        : _createCommentVNode("", true),
      (!_ctx.hideBalance)
        ? (_openBlock(), _createBlock(_component_header_profile_balance, { key: 1 }))
        : _createCommentVNode("", true),
      _createVNode(_component_header_notifications),
      _createVNode(_component_tm_button, {
        size: "large",
        "icon-only": "",
        flat: "",
        color: "transparent",
        target: "_blank",
        href: "https://support.textmagic.com/",
        class: "ml-1"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_icon, {
            name: "help_outline",
            size: "xLarge"
          })
        ]),
        _: 1
      }),
      _createVNode(_component_header_profile, { class: "ml-4" })
    ]),
    _: 1
  }))
}