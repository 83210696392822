import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_drop_ups_container = _resolveComponent("drop-ups-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.containerName), {
      "show-sidebar-on-hover": _ctx.showSidebarOnHover,
      "hide-balance": _ctx.hideBalance,
      "sidebar-collapsed": _ctx.sidebarCollapsed
    }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }, 8, ["show-sidebar-on-hover", "hide-balance", "sidebar-collapsed"])),
    _createVNode(_component_drop_ups_container)
  ], 64))
}