
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const getIcon = (type: string) => {
      switch (type) {
        case 'success':
          return 'check_circle'
        case 'warning':
          return 'warning'
        case 'error':
          return 'error'
        case 'info':
          return 'info'
        default:
          return ''
      }
    }

    return {
      getIcon,
    }
  },
})
