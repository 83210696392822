import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d20bba6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-btn-dropdown-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_button, {
      color: _ctx.disabled ? 'white' : 'primary',
      "no-caps": "",
      unelevated: "",
      class: _normalizeClass(["tm-btn-main", { 'tm-btn-main--disabled': _ctx.disabled }]),
      ripple: false,
      label: _ctx.label,
      disabled: _ctx.disabled,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('btn-click')))
    }, {
      default: _withCtx(() => [
        (_ctx.buttonTooltip?.length)
          ? (_openBlock(), _createBlock(_component_tm_tooltip, { key: 0 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.buttonTooltip), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["color", "class", "label", "disabled"]),
    _createVNode(_component_tm_dropdown, {
      position: "top-left",
      class: "tm-btn-dropdown",
      "close-on-content-click": true,
      persistent: "",
      disable: _ctx.disabled
    }, {
      menu: _withCtx(({ updatePosition, close }) => [
        _renderSlot(_ctx.$slots, "menu", _normalizeProps(_guardReactiveProps({ updatePosition, close })), undefined, true)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_tm_button, {
          color: _ctx.disabled ? 'white' : 'primary',
          class: _normalizeClass(["tm-btn-additional", { 'tm-btn-additional--disabled': _ctx.disabled }]),
          disabled: _ctx.disabled
        }, {
          default: _withCtx(() => [
            (_ctx.dropdownTooltip?.length)
              ? (_openBlock(), _createBlock(_component_tm_tooltip, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.dropdownTooltip), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_tm_icon, {
              name: "arrow_drop_down",
              size: "xxLarge",
              class: _normalizeClass({'light--text': _ctx.disabled})
            }, null, 8, ["class"])
          ]),
          _: 1
        }, 8, ["color", "class", "disabled"])
      ]),
      _: 3
    }, 8, ["disable"])
  ]))
}