import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f10d5786"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialpad-footer" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_button, {
      color: _ctx.getState === 'main' ? 'primary' : 'secondary',
      "icon-only": "",
      rounded: "",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setState('main', true)))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_icon, {
          name: _ctx.getState === 'main' ? 'phone' : 'dialpad',
          "custom-size": "28px"
        }, null, 8, ["name"])
      ]),
      _: 1
    }, 8, ["color"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.btns, (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: _normalizeClass(["dialpad-footer__btn", {
        'dialpad-footer__btn--active': _ctx.getState === item.name,
      }]),
        onClick: ($event: any) => (_ctx.setState(item.name, true))
      }, [
        _createVNode(_component_tm_icon, {
          name: item.icon,
          size: "xxxLarge"
        }, null, 8, ["name"]),
        _createElementVNode("div", null, _toDisplayString(_ctx.capitalizeFirstLetter(item.name)), 1)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}