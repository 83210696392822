
import { defineComponent } from 'vue'
import { useDialpad } from '@/compositions/chats/chats'

export default defineComponent({
  setup() {
    const { fieldValue, getState } = useDialpad()

    return {
      getState,
      fieldValue,
    }
  },
})
