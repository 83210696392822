
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'

export default defineComponent({
  components: { TmModal },
  setup() {
    const tmModal = ref()

    // using from parents
    const openModal = () => {
      tmModal.value.openModal()
    }
    const hideModal = () => {
      tmModal.value.hideModal()
    }
    return {
      tmModal,
      openModal,
      hideModal,
    }
  },
})
