import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!

  return (_openBlock(), _createBlock(_component_tm_avatar, {
    "custom-size": "40px",
    "placeholder-custom-size": _ctx.chatSender.person && _ctx.chatSender.person.fullName ? '18px' : '20px',
    "custom-icon": _ctx.chatSender.person ? 'person' : 'phone',
    closed: _ctx.closed,
    url: _ctx.chatSender.person ? _ctx.chatSender.person.avatar : '',
    name: _ctx.chatSender.person ? _ctx.chatSender.person.fullName : _ctx.chatSender.phone,
    color: _ctx.chatAvatarColor,
    "status-size": "large",
    "placeholder-type": _ctx.chatSender.person && _ctx.chatSender.person.fullName ? 'symbol' : 'icon'
  }, {
    status: _withCtx(() => [
      _renderSlot(_ctx.$slots, "status")
    ]),
    _: 3
  }, 8, ["placeholder-custom-size", "custom-icon", "closed", "url", "name", "color", "placeholder-type"]))
}