
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import useSubAccount from '@/compositions/subAccount'

export default defineComponent({
  components: {
    TmButton,
  },
  setup() {
    const { subAccountText, subAccountLogout } = useSubAccount()

    return { subAccountText, subAccountLogout }
  },
})
