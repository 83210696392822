
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    isOpened: {
      type: Boolean,
    },
  },
})
