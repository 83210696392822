
import { defineComponent, computed } from 'vue'
import TmContent from '@/components/layout/TmContent.vue'
import TmButton from '@/components/shared/TmButton.vue'
import MainSidebar from '@/components/layout/mainSidebar/MainSidebar.vue'
import TmMobileMenu from '@/components/layout/mobileMenu/TmMobileMenu.vue'
import TmNotifications from '@/components/shared/TmNotifications.vue'
import TmModalContainer from '@/components/modals/TmModalContainer.vue'
import HeaderBase from '@/components/layout/header/HeaderBase.vue'
import HeaderMobile from '@/components/layout/header/HeaderMobile.vue'
import TmOverlay from '@/components/layout/TmOverlay.vue'
import navigationService from '@/services/navigationService'
import { useBreakpoints } from '@/compositions/breakpoints'
import { useMobileNavigation } from '@/compositions/mobileNavigation'
import useSubAccount from '@/compositions/subAccount'
import HeaderSubAccount from '@/components/layout/header/HeaderSubAccount.vue'
import BottomBar from '@/components/layout/bottomBar/BottomBar.vue'
import ChatsActiveCall from '@/components/pages/chats/activeCall/ChatsActiveCall.vue'
import Dialpad from '@/components/pages/chats/dialpad/Dialpad.vue'
import { useDialpad, useCalls } from '@/compositions/chats/chats'

export default defineComponent({
  components: {
    HeaderSubAccount,
    BottomBar,
    TmButton,
    TmMobileMenu,
    TmOverlay,
    TmModalContainer,
    TmContent,
    MainSidebar,
    TmNotifications,
    HeaderBase,
    HeaderMobile,
    ChatsActiveCall,
    Dialpad,
  },
  props: {
    showSidebarOnHover: {
      type: Boolean,
    },
    sidebarCollapsed: {
      type: Boolean,
    },
    hideBalance: {
      type: Boolean,
    },
  },
  setup() {
    const routes = navigationService.getBaseRoutes()
    const { closeNavigation } = useMobileNavigation()

    const { subAccountText } = useSubAccount()

    const { isMdMax, isLgMax, isXlMax } = useBreakpoints()
    const { getOverlayState } = useMobileNavigation()

    const isSmallLaptop = computed(() => !isMdMax.value && isLgMax.value)
    const headerComponent = computed(() => isMdMax.value ? 'header-mobile' : 'header-base')
    const { showDialpad } = useDialpad()
    const {
      showCall,
      getActiveCall: activeCall,
    } = useCalls()

    return {
      closeNavigation,
      routes,
      isLgMax,
      isMdMax,
      isXlMax,
      headerComponent,
      getOverlayState,
      subAccountText,
      showDialpad,
      showCall,
      activeCall,
      isSmallLaptop,
    }
  },
})
