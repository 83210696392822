
import type { PropType } from 'vue'
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import DropUpDefaultView from '@/components/layout/dropUp/DropUpDefaultView.vue'
import DropUpModalView from '@/components/layout/dropUp/DropUpModalView.vue'
import DropUpContent from '@/components/layout/dropUp/DropUpContent.vue'
import type { DropUpName } from '@/definitions/_layout/dropUps/types'
import { useDropUps } from '@/compositions/dropups'

export default defineComponent({
  components: {
    DropUpDefaultView,
    DropUpModalView,
    DropUpContent,
  },
  props: {
    dropUpName: {
      type: String as PropType<DropUpName>,
      required: true,
    },
  },
  setup(props) {
    const modalViewRef = ref()
    const isModalView = ref(false)
    const isOpened = ref(false)
    const { getDropUpData } = useDropUps()

    const dropUpValue = reactive(getDropUpData(props.dropUpName).value)

    const totalRecipients = computed(() => {
      return [
        ...(Array.isArray(dropUpValue.to) ? dropUpValue.to : []),
        ...(Array.isArray(dropUpValue.cc) ? dropUpValue.cc : []),
        ...(Array.isArray(dropUpValue.bcc) ? dropUpValue.bcc : []),
      ]
    })

    const isCampaign = computed(() => props.dropUpName === 'email' && totalRecipients.value.length > 5)

    const openModal = () => {
      isModalView.value = true
      modalViewRef.value.openModal()
    }

    const minimizeModal = () => {
      modalViewRef.value.hideModal()
      isOpened.value = false
    }

    const modalToDefault = () => {
      modalViewRef.value.hideModal()
      isOpened.value = true
    }

    const beforeModalHide = () => {
      isModalView.value = false
    }

    onMounted(() => {
      isOpened.value = true
    })

    return {
      modalViewRef,
      dropUpValue,
      isModalView,
      isOpened,
      openModal,
      minimizeModal,
      modalToDefault,
      beforeModalHide,
      totalRecipients,
      isCampaign,
    }
  },
})
