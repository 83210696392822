
import { defineComponent, ref } from 'vue'
import TmDropdownSelect from '@/components/shared/dropdowns/TmDropdownSelect.vue'
import TmButton from '@/components/shared/TmButton.vue'
import useSmsSurvey from '@/compositions/services/useSmsSurvey'

export default defineComponent({
  name: 'SurveysHeaderMainInfo',
  components: { TmButton, TmDropdownSelect },
  setup() {
    const { openEditSurveyNameModal } = useSmsSurvey()
    const draftFrom = [
      '18 Mar 2022 22:30',
      '17 Mar 2022 19:20',
      '16 Mar 2022 10:39',
      '4 Mar 2022 7:00',
      '2 Mar 2022 11:05',
      '2 Mar 2022 5:12',
      '1 Mar 2022 2:22',
    ]
    const lastSaved = ref('18 Mar 2022 23:07')

    return {
      openEditSurveyNameModal,
      draftFrom,
      lastSaved,
    }
  },
})
