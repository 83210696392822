import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eccaa264"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-notification-item" }
const _hoisted_2 = { class: "flex-grow-1" }
const _hoisted_3 = { class: "header-notification-item__top" }
const _hoisted_4 = { class: "header-notification-item__description" }
const _hoisted_5 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_6 = {
  key: 1,
  class: "mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_tm_stylized_text = _resolveComponent("tm-stylized-text")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "avatar-slot", {}, () => [
      _createVNode(_component_tm_avatar, {
        url: _ctx.notification.person.avatar,
        name: _ctx.notification.person.fullName,
        color: _ctx.notification.person.avatarColor,
        size: "large"
      }, null, 8, ["url", "name", "color"])
    ], true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["header-notification-item__top-title", {
            'header-notification-item__top-title--new': _ctx.notification.type === 'new'
          }]),
          to: _ctx.notification.link
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.notification.title), 1)
          ]),
          _: 1
        }, 8, ["class", "to"]),
        _createElementVNode("div", {
          class: _normalizeClass(["header-notification-item__top-date", {
            'header-notification-item__top-date--unread': _ctx.notification.unread
          }])
        }, _toDisplayString(_ctx.notification.date), 3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createVNode(_component_tm_stylized_text, {
            text: _ctx.notification.description,
            class: "lh-20"
          }, null, 8, ["text"])
        ], true)
      ]),
      (_ctx.hasInfoSlot)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "info-slot", {}, undefined, true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.hasButtonSlot || _ctx.notification.btnText)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "button-slot", {}, () => [
              _createVNode(_component_tm_button, {
                size: "medium",
                color: _ctx.notification.btnColor,
                to: _ctx.notification.link
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_tm_icon, {
                    name: _ctx.notification.btnIcon,
                    size: "small",
                    left: ""
                  }, null, 8, ["name"]),
                  _createElementVNode("span", null, _toDisplayString(_ctx.notification.btnText), 1)
                ]),
                _: 1
              }, 8, ["color", "to"])
            ], true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}