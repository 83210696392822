
import { computed, defineComponent } from 'vue'
import type { DropUpName, CustomDropUpName } from '@/definitions/_layout/dropUps/types'
import { DropUps, CustomDropUps } from '@/definitions/_layout/dropUps/types'
import { useDropUps } from '@/compositions/dropups'
import DropUp from '@/components/layout/dropUp/DropUp.vue'

export default defineComponent({
  components: {
    DropUp,
  },
  setup() {
    const { getActiveDropUps } = useDropUps()
    const allActiveDropUps = getActiveDropUps()
    const activeDropUps = computed(() => allActiveDropUps.value.filter(item => item in DropUps) as DropUpName[])
    const activeCustomDropUps = computed(() => allActiveDropUps.value.filter(item => item in CustomDropUps) as CustomDropUpName[])
    return {
      activeDropUps,
      activeCustomDropUps,
    }
  },
})
