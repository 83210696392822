
import type { PropType } from 'vue'
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import HeaderNotificationItemBalance from '@/components/layout/header/headerNotifications/HeaderNotificationItemBalance.vue'
import HeaderNotificationItemTask from '@/components/layout/header/headerNotifications/HeaderNotificationItemTask.vue'
import HeaderNotificationItemCall from '@/components/layout/header/headerNotifications/HeaderNotificationItemCall.vue'
import HeaderNotificationItemImport from '@/components/layout/header/headerNotifications/HeaderNotificationItemImport.vue'
import HeaderNotificationItemNew from '@/components/layout/header/headerNotifications/HeaderNotificationItemNew.vue'
import HeaderNotificationItem from '@/components/layout/header/headerNotifications/HeaderNotificationItem.vue'
import type { NotificationsType, NotificationTypes } from '@/definitions/notifications/types'

export default defineComponent({
  components: {
    TmButton,
    TmScrollbar,
    HeaderNotificationItemBalance,
    HeaderNotificationItemTask,
    HeaderNotificationItemCall,
    HeaderNotificationItemImport,
    HeaderNotificationItemNew,
    HeaderNotificationItem,
  },
  props: {
    notifications: {
      type: Array as PropType<NotificationsType[]>,
      default: () => [],
    },
  },
  emits: ['markAllRead'],
  setup() {
    const refs: any = ref([])
    const onScroll = () => {
      for (let i = 0; i < refs.value.length - 1; i++) {
        const currentTop = refs.value[i].getBoundingClientRect().top
        if (currentTop > 106) {
          refs.value[i].classList.remove('header-notifications-list__day-top--relative')
          refs.value[i + 1].classList.remove('header-notifications-list__day-top--sticky')
        } else {
          refs.value[i].classList.add('header-notifications-list__day-top--relative')
          refs.value[i + 1].classList.add('header-notifications-list__day-top--sticky')
        }
      }

      for (let i = 1; i < refs.value.length - 1; i++) {
        const currentTop = refs.value[i].getBoundingClientRect().top
        if (currentTop > 160) {
          refs.value[i - 1].classList.remove('header-notifications-list__day-top--relative')
        }
      }
    }
    const notificationComponentMap: Record<string, string> = {
      balance: 'header-notification-item-balance',
      task: 'header-notification-item-task',
      call: 'header-notification-item-call',
      import: 'header-notification-item-import',
      new: 'header-notification-item-new',
      contact: 'header-notification-item',
    }

    const notificationComponent = (type: NotificationTypes) => {
      return notificationComponentMap[type]
    }

    return {
      refs,
      onScroll,
      notificationComponent,
    }
  },
})
