import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dialpad_header_top = _resolveComponent("dialpad-header-top")!
  const _component_dialpad_header_field = _resolveComponent("dialpad-header-field")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dialpad-header", {
      'dialpad-header--without-field': _ctx.withoutField,
    }])
  }, [
    _createVNode(_component_dialpad_header_top),
    (!_ctx.withoutField)
      ? (_openBlock(), _createBlock(_component_dialpad_header_field, { key: 0 }))
      : _createCommentVNode("", true)
  ], 2))
}