
import { defineComponent } from 'vue'
import navigationService from '@/services/navigationService'
import MainSidebar from '@/components/layout/mainSidebar/MainSidebar.vue'
import TmModalContainer from '@/components/modals/TmModalContainer.vue'
import TmContent from '@/components/layout/TmContent.vue'
import SurveysHeader from '@/components/layout/header/surveys/SurveysHeader.vue'
import SurveysFooter from '@/components/layout/footer/SurveysFooter.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  name: 'SurveysContainer',
  components: {
    SurveysFooter,
    SurveysHeader,
    TmContent,
    TmModalContainer,
    MainSidebar,
  },
  setup() {
    const routes = navigationService.getBaseRoutes()
    const { isXlMax, isMdMax } = useBreakpoints()

    return {
      isXlMax,
      isMdMax,
      routes,
    }
  },
})
