import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0596f7dd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialpad" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dialpad_header = _resolveComponent("dialpad-header")!
  const _component_dialpad_history_filter = _resolveComponent("dialpad-history-filter")!
  const _component_dialpad_contacts = _resolveComponent("dialpad-contacts")!
  const _component_dialpad_settings = _resolveComponent("dialpad-settings")!
  const _component_dialpad_numpad = _resolveComponent("dialpad-numpad")!
  const _component_dialpad_footer = _resolveComponent("dialpad-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_dialpad_header),
    (_ctx.getState === 'history')
      ? (_openBlock(), _createBlock(_component_dialpad_history_filter, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.getState !== 'settings')
      ? (_openBlock(), _createBlock(_component_dialpad_contacts, {
          key: 1,
          extended: _ctx.getState !== 'main',
          "show-alphabet": _ctx.getState === 'contacts'
        }, null, 8, ["extended", "show-alphabet"]))
      : (_openBlock(), _createBlock(_component_dialpad_settings, { key: 2 })),
    (_ctx.getState === 'main')
      ? (_openBlock(), _createBlock(_component_dialpad_numpad, { key: 3 }))
      : _createCommentVNode("", true),
    _createVNode(_component_dialpad_footer)
  ]))
}