
import { defineComponent } from 'vue'
import TmHeader from '@/components/layout/header/Header.vue'
import TmButton from '@/components/shared/TmButton.vue'
import HeaderProfileMobile from '@/components/layout/header/HeaderProfileMobile.vue'
import { useMobileNavigation } from '@/compositions/mobileNavigation'

export default defineComponent({
  components: {
    HeaderProfileMobile,
    TmButton,
    TmHeader,
  },
  setup() {
    const { updateMenuState, getMenuState } = useMobileNavigation()
    const assignee = {
      avatarColor: 'orange',
      fullName: 'John Doe',
    }

    return {
      assignee,
      updateMenuState,
      getMenuState,
    }
  },
})
