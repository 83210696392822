
import { defineComponent, computed } from 'vue'
import DialpadHeaderTop from '@/components/pages/chats/dialpad/header/DialpadHeaderTop.vue'
import DialpadHeaderField from '@/components/pages/chats/dialpad/header/DialpadHeaderField.vue'
import { useDialpad } from '@/compositions/chats/chats'

export default defineComponent({
  components: {
    DialpadHeaderField,
    DialpadHeaderTop,
  },
  setup() {
    const { getState } = useDialpad()
    const withoutField = computed(() => getState.value === 'settings')

    return {
      getState,
      withoutField,
    }
  },
})
