
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { DropUpName } from '@/definitions/_layout/dropUps/types'
import { useDropUps } from '@/compositions/dropups'
import TmTooltip from '@/components/shared/TmTooltip.vue'

export default defineComponent({
  components: {
    TmTooltip,
    TmButton,
  },
  props: {
    dropUpName: {
      type: String as PropType<DropUpName>,
      required: true,
    },
    isOpened: {
      type: Boolean,
    },
    isModalView: {
      type: Boolean,
    },
    isCampaign: {
      type: Boolean,
    },
  },
  emits: ['toggle-view', 'toggle-modal-view'],
  setup(props) {
    const { closeDropUp, getDropUpData } = useDropUps()

    const headerTitle = getDropUpData(props.dropUpName).title

    return {
      closeDropUp,
      headerTitle,
    }
  },
})
