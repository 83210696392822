export enum DropUps {
  textMessage,
  email
}
export enum CustomDropUps {
  filesUploading
}
export type DropUpName = keyof typeof DropUps
export type CustomDropUpName = keyof typeof CustomDropUps

export type DropUpValueType = {
  from: string | { label: string };
  to: any[];
  cc?: any[];
  bcc?: any[];
  subject?: string;
  message: string;
  schedule: string;
}
