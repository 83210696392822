import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_drop_up_content = _resolveComponent("drop-up-content")!
  const _component_drop_up_default_view = _resolveComponent("drop-up-default-view")!
  const _component_drop_up_modal_view = _resolveComponent("drop-up-modal-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["drop-up-wrapper", { 'drop-up-wrapper--opened': _ctx.isOpened }])
  }, [
    (!_ctx.isModalView)
      ? (_openBlock(), _createBlock(_component_drop_up_default_view, {
          key: 0,
          "is-opened": _ctx.isOpened
        }, {
          default: _withCtx(() => [
            _createVNode(_component_drop_up_content, {
              modelValue: _ctx.dropUpValue,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dropUpValue) = $event)),
              "drop-up-name": _ctx.dropUpName,
              "is-opened": _ctx.isOpened,
              "is-campaign": _ctx.isCampaign,
              "total-recipients": _ctx.totalRecipients,
              onToggleView: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isOpened = !_ctx.isOpened)),
              onToggleModalView: _ctx.openModal
            }, null, 8, ["modelValue", "drop-up-name", "is-opened", "is-campaign", "total-recipients", "onToggleModalView"])
          ]),
          _: 1
        }, 8, ["is-opened"]))
      : _createCommentVNode("", true),
    _createVNode(_component_drop_up_modal_view, {
      ref: "modalViewRef",
      onBeforeHide: _ctx.beforeModalHide
    }, {
      default: _withCtx(() => [
        _createVNode(_component_drop_up_content, {
          modelValue: _ctx.dropUpValue,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dropUpValue) = $event)),
          "drop-up-name": _ctx.dropUpName,
          "is-campaign": _ctx.isCampaign,
          "total-recipients": _ctx.totalRecipients,
          "is-modal-view": "",
          onToggleView: _ctx.minimizeModal,
          onToggleModalView: _ctx.modalToDefault
        }, null, 8, ["modelValue", "drop-up-name", "is-campaign", "total-recipients", "onToggleView", "onToggleModalView"])
      ]),
      _: 1
    }, 8, ["onBeforeHide"])
  ], 2))
}