import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_sidebar = _resolveComponent("main-sidebar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tm-mobile-menu", {
      'tm-mobile-menu--active': _ctx.getMenuState,
    }])
  }, [
    _createVNode(_component_main_sidebar, {
      menu: _ctx.menu,
      "show-children": "",
      "hide-logo": "",
      "hide-minimize-button": "",
      class: "py-2",
      opened: ""
    }, null, 8, ["menu"])
  ], 2))
}