
import { defineComponent, computed } from 'vue'
import { useCalls } from '@/compositions/chats/chats'
import { capitalizeFirstLetter } from '@/services/string'
import ChatAvatar from '@/components/shared/chat/ChatAvatar.vue'
import TmFlag from '@/components/shared/TmFlag.vue'
import TmButton from '@/components/shared/TmButton.vue'

type ButtonType = {
  icon: string;
  text: string;
  class?: string;
  clickEvent?: () => void;
}

export default defineComponent({
  components: { ChatAvatar, TmFlag, TmButton },
  setup() {
    const { getActiveCall: activeCall, currentView, showCall, toggleMute, setActiveCall } = useCalls()
    const buttons = computed((): ButtonType[] => [
      {
        icon: 'mic',
        text: 'Mute',
        class: activeCall.value!.muted ? 'chats-active-call-main__button--muted' : '',
        clickEvent: () => toggleMute(),
      },
      {
        icon: 'forum',
        text: 'Chat',
      },
      {
        icon: 'person',
        text: 'Profile',
      },
      {
        icon: 'settings',
        text: 'Settings',
        clickEvent: () => {
          currentView.value = 'settings'
        },
      },
    ])

    return {
      setActiveCall,
      activeCall,
      buttons,
      showCall,
      capitalizeFirstLetter,
    }
  },
})
