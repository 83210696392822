
import { defineComponent } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'

export default defineComponent({
  components: { TmTooltip, TmButton, TmDropdown },
  props: {
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    buttonTooltip: {
      type: String,
    },
    dropdownTooltip: {
      type: String,
    },
  },
  emits: ['btn-click'],
})
