
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useDialpad } from '@/compositions/chats/chats'
import { capitalizeFirstLetter } from '@/services/string'

export default defineComponent({
  components: {
    TmButton,
  },
  setup() {
    const { setState, getState } = useDialpad()
    const btns = ref([
      {
        name: 'history',
        icon: 'history',
      },
      {
        name: 'contacts',
        icon: 'people',
      },
    ])

    return {
      btns,
      setState,
      capitalizeFirstLetter,
      getState,
    }
  },
})
