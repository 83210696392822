import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8b2837fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialpad-numpad" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "hero-24 neutral--text lh-32" }
const _hoisted_4 = {
  key: 0,
  class: "body-text-semi-bold-12 lh-16 distinct--text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: "dialpad-numpad__item",
        onClick: ($event: any) => (_ctx.fieldValue = _ctx.fieldValue + item.num)
      }, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(item.num), 1),
        (item.text)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(item.text), 1))
          : _createCommentVNode("", true)
      ], 8, _hoisted_2))
    }), 128))
  ]))
}