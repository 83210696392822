import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77090706"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-container__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_notifications = _resolveComponent("tm-notifications")!
  const _component_header_sub_account = _resolveComponent("header-sub-account")!
  const _component_main_sidebar = _resolveComponent("main-sidebar")!
  const _component_tm_mobile_menu = _resolveComponent("tm-mobile-menu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_tm_modal_container = _resolveComponent("tm-modal-container")!
  const _component_tm_overlay = _resolveComponent("tm-overlay")!
  const _component_bottom_bar = _resolveComponent("bottom-bar")!
  const _component_tm_content = _resolveComponent("tm-content")!
  const _component_dialpad = _resolveComponent("dialpad")!
  const _component_chats_active_call = _resolveComponent("chats-active-call")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-container", {
      'sub-account-active': _ctx.subAccountText,
    }])
  }, [
    _createVNode(_component_tm_notifications),
    (_ctx.subAccountText)
      ? (_openBlock(), _createBlock(_component_header_sub_account, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["base-container__page", {
        'base-container__page--show-sidebar-on-hover': _ctx.showSidebarOnHover,
      }])
    }, [
      (!_ctx.isMdMax)
        ? (_openBlock(), _createBlock(_component_main_sidebar, {
            key: 0,
            menu: _ctx.routes,
            opened: !_ctx.sidebarCollapsed && (!(_ctx.isSmallLaptop) || _ctx.getOverlayState),
            "show-sidebar-on-hover": _ctx.showSidebarOnHover
          }, null, 8, ["menu", "opened", "show-sidebar-on-hover"]))
        : (_openBlock(), _createBlock(_component_tm_mobile_menu, {
            key: 1,
            menu: _ctx.routes
          }, null, 8, ["menu"])),
      _createVNode(_component_tm_content, null, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.headerComponent), { "hide-balance": _ctx.hideBalance }, null, 8, ["hide-balance"])),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_router_view)
          ]),
          _createVNode(_component_tm_modal_container),
          _createVNode(_component_tm_overlay, {
            active: _ctx.getOverlayState && _ctx.isLgMax,
            "cover-header": _ctx.isSmallLaptop,
            onClick: _ctx.closeNavigation
          }, null, 8, ["active", "cover-header", "onClick"]),
          _createVNode(_component_bottom_bar)
        ]),
        _: 1
      })
    ], 2),
    (_ctx.showDialpad)
      ? (_openBlock(), _createBlock(_component_dialpad, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.showCall && _ctx.activeCall)
      ? (_openBlock(), _createBlock(_component_chats_active_call, { key: 2 }))
      : _createCommentVNode("", true)
  ], 2))
}