
import { defineComponent } from 'vue'
import TmAutoSizePanel from '@/components/shared/autoSize/TmAutoSizePanel.vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'
import TmButton from '@/components/shared/TmButton.vue'
import useSmsSurvey from '@/compositions/services/useSmsSurvey'
import { useModals } from '@/compositions/modals'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  name: 'SurveysFooter',
  components: { TmButton, TmAutoSizePanel, TmAutoSizePanelItemButton },
  setup() {
    const { openModal } = useModals()
    const { isSmMax } = useBreakpoints()
    const { openPreviewSurveyModal, openScheduleSurveyModal } = useSmsSurvey()

    const openSendSurveyModal = () => {
      openModal('confirmation', {
        title: 'Send SMS survey',
        btnText: isSmMax.value ? 'Send survey' : 'Send survey to 1 recipient',
        cancelButtonText: 'Back to editing',
        textContainer: 'div',
        text: [
          { text: 'Are you sure you would like to send this survey to 1 recipient?' },
          { text: 'This action cannot be undone later.', style: 'semi-bold' },
        ],
      })
    }

    return {
      openPreviewSurveyModal,
      openSendSurveyModal,
      openScheduleSurveyModal,
    }
  },
})
