import { ref } from 'vue'
import type { NotificationsType } from '@/definitions/notifications/types'

export const notificationsList = ref<NotificationsType[]>([
  {
    date: 'today',
    notifications: [
      {
        type: 'balance',
        date: 'Just now',
        title: 'Low balance alert',
        description: [
          'The balance of your account has fallen to ',
          { text: '$4.88', style: 'semi-bold', color: 'red' },
          '. Add credit to your account to avoid service interruptions.',
        ],
        link: { name: 'base.admin.overview' },
        unread: true,
      },
      {
        type: 'task',
        date: '10 min ago',
        title: 'Robert Overit commented on a task',
        link: { name: 'base.tasks.kanban' },
        person: {
          uid: '1224ddc9-27aa-454d-a6e0-cbbebefaa156',
          firstName: 'Ruth',
          lastName: 'Adkins',
          fullName: 'Ruth Adkins',
          avatar: 'https://randomuser.me/api/portraits/women/15.jpg',
          avatarColor: 'green',
        },
        task: {
          title: '[Back] Tickets - Chat ID link in the internal note notification email is displayed as plain text',
          priority: '300',
          comment: 'Create ticket after task will be done. When a new incoming message reaches your virtual Textmagic number, you will get notified via a desktop alert and - if needed - via email and mobile app push notifications.',
        },
        unread: true,
      },
      {
        type: 'task',
        date: '45 min ago',
        title: 'Robert Overit commented on a task',
        link: { name: 'base.tasks.kanban' },
        person: {
          uid: '1224ddc9-27aa-454d-a6e0-cbbebefaa156',
          firstName: 'Ruth',
          lastName: 'Adkins',
          fullName: 'Ruth Adkins',
          avatar: 'https://randomuser.me/api/portraits/women/15.jpg',
          avatarColor: 'green',
        },
        task: {
          title: '[Back] Tickets - Chat ID link in the internal note notification email is displayed as plain text',
          priority: '200',
        },
        unread: true,
      },
      {
        type: 'call',
        date: '1 hour ago',
        title: 'Missed call from +1 (298) 7392 723',
        description: '+1 (298) 7392 723 called your number +1 (234) 567 890, but the call was not forwarded to you.',
        link: { name: 'base.history.inboundCalls' },
        unread: true,
      },
      {
        type: 'import',
        date: '2 hour ago',
        title: 'Import session completed',
        description: '567 contacts have been successfully imported from UK_contacts.csv.',
        link: { name: 'base.contacts.import.details' },
        unread: true,
      },
    ],
  },
  {
    date: 'yesterday',
    notifications: [
      {
        type: 'new',
        date: '10:52 pm',
        title: 'New feature',
        description: [
          'Connect your ',
          { text: 'WhatsApp Business account', style: 'semi-bold' },
          ' and respond to WhatsApp chats directly from the Textmagic app.',
        ],
        link: { name: 'base.chats.settings.whatsApp' },
        logo: {
          name: 'Whatsapp',
          avatar: require('@/assets/images/chats/whatsapp-type.svg'),
          avatarColor: 'green',
        },
        btnIcon: 'add',
        btnText: 'Connect WhatsApp',
      },
      {
        type: 'balance',
        date: '08:03 pm',
        title: 'Number cancelation warning',
        description: [
          'The number +1 (298) 7392 723 is due for renewal on 23 Aug. Your balance is insufficient for the renewal: ',
          { text: '$4.88', style: 'semi-bold', color: 'red' },
          '.',
        ],
        link: { name: 'base.chats.settings.numberDetails' },
      },
      {
        type: 'balance',
        date: '11:20 am',
        title: 'Scheduled message not sent',
        description: [
          'Your scheduled text message was not sent because the account balance is too low: ',
          { text: '$4.88', style: 'semi-bold', color: 'red' },
          '.',
        ],
        link: { name: 'base.campaigns.sms.scheduled' },
      },
      {
        type: 'contact',
        date: '09:21 am',
        title: 'Contact unsubscribed',
        description: [
          'Your contact James Cameron has unsubscribed from your list.',
        ],
        link: { name: 'base.contacts.unsubscribed' },
        person: {
          uid: '1',
          firstName: 'Robert',
          lastName: 'Overit',
          fullName: 'Robert Overit',
          avatar: '',
          avatarColor: 'blue',
        },
      },
    ],
  },
  {
    date: 'older',
    notifications: [
      {
        type: 'balance',
        date: '9 Jul',
        title: 'Scheduled message not sent',
        description: [
          'Your scheduled text message was not sent because the account balance is too low: ',
          { text: '$4.88', style: 'semi-bold', color: 'red' },
          '.',
        ],
        link: { name: 'base.campaigns.sms.scheduled' },
      },
    ],
  },
])
