import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-014a7e7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialpad-history-filter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createBlock(_component_tm_button, {
        key: i,
        color: _ctx.getCurrentFilter === item.name ? 'secondary' : 'default',
        hovered: _ctx.getCurrentFilter === item.name,
        onClick: ($event: any) => (_ctx.setCurrentFilter(item.name))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(item.label), 1)
        ]),
        _: 2
      }, 1032, ["color", "hovered", "onClick"]))
    }), 128))
  ]))
}