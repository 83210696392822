
import { defineComponent } from 'vue'
import { useDialpad } from '@/compositions/chats/chats'

type NumType = {
  num: string;
  text?: string;
}

export default defineComponent({
  setup() {
    const { fieldValue } = useDialpad()
    const items: NumType[] = [
      {
        num: '1',
      },
      {
        num: '2',
        text: 'abc',
      },
      {
        num: '3',
        text: 'def',
      },
      {
        num: '4',
        text: 'ghi',
      },
      {
        num: '5',
        text: 'jkl',
      },
      {
        num: '6',
        text: 'mno',
      },
      {
        num: '7',
        text: 'pqrs',
      },
      {
        num: '8',
        text: 'tuv',
      },
      {
        num: '9',
        text: 'wxyz',
      },
      {
        num: '*',
      },
      {
        num: '0',
        text: '+',
      },
      {
        num: '#',
      },
    ]

    return {
      items,
      fieldValue,
    }
  },
})
