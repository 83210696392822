import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35971c99"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "surveys-header" }
const _hoisted_2 = { class: "surveys-header__left" }
const _hoisted_3 = { class: "surveys-header__right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_surveys_header_main_info = _resolveComponent("surveys-header-main-info")!
  const _component_surveys_header_actions = _resolveComponent("surveys-header-actions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_surveys_header_main_info)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_surveys_header_actions)
    ])
  ]))
}