
import { defineComponent, computed } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import Highlight from '@/components/shared/Highlight.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import type { PersonType } from '@/definitions/shared/types'
import type { PropType } from 'vue'
import { useDialpad } from '@/compositions/chats/chats'
import { capitalizeFirstLetter } from '@/services/string'
import { formatUntilOneDay } from '@/services/dateTimeService'

type IconType = {
  name: string;
  class: string;
}

export default defineComponent({
  components: {
    TmButton,
    TmTooltip,
    Highlight,
    TmAvatar,
  },
  props: {
    person: {
      type: Object as PropType<PersonType>,
    },
    phone: {
      type: String,
    },
    callType: {
      type: String as PropType<'outbound' | 'inbound' | 'missed'>,
    },
    duration: {
      type: String,
    },
    date: {
      type: String,
    },
  },
  setup(props) {
    const { fieldValue } = useDialpad()
    const computedIcon = computed((): IconType | void => {
      if (!props.callType) { return }
      switch (props.callType) {
        case 'outbound': return { name: 'tmi-phone-forwarded', class: 'primary--text' }
        case 'inbound': return { name: 'phone_callback', class: 'success--text' }
        default: return { name: 'phone_missed', class: 'error--text' }
      }
    })

    return {
      capitalizeFirstLetter,
      fieldValue,
      computedIcon,
      formatUntilOneDay,
    }
  },
})
