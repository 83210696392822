import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b2d7bf8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "info-block" }
const _hoisted_2 = { class: "info-block__icon" }
const _hoisted_3 = { class: "subhead-semi-bold-16" }
const _hoisted_4 = { class: "info-block__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_emoji = _resolveComponent("tm-emoji")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_tm_emoji, {
        name: _ctx.icon,
        "custom-size": "32px"
      }, null, 8, ["name"])
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}