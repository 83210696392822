import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0159f4cb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notification__body" }
const _hoisted_2 = { class: "notification__title lh-20" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createBlock(_component_notifications, {
    class: "tm-notifications",
    width: "430"
  }, {
    body: _withCtx((props) => [
      _createElementVNode("div", {
        class: _normalizeClass(["notification", `notification--${props.item.type}`])
      }, [
        _createVNode(_component_tm_icon, {
          class: "notification__icon",
          name: _ctx.getIcon(props.item.type),
          size: "xLarge"
        }, null, 8, ["name"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(props.item.title), 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "notification__content body-text-regular-14 lh-20",
              innerHTML: props.item.text
            }, null, 8, _hoisted_4)
          ])
        ]),
        _createVNode(_component_tm_icon, {
          class: "close",
          size: "xLarge",
          name: "close",
          onClick: props.close
        }, null, 8, ["onClick"])
      ], 2)
    ]),
    _: 1
  }))
}