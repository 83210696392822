
import type { PropType } from 'vue'
import { defineComponent, ref, nextTick, computed } from 'vue'
import { useModals } from '@/compositions/modals'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmButtonDropdown from '@/components/shared/TmButtonDropdown.vue'
import useComposeOpenModal from '@/compositions/compose/useComposeOpenModal'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { EditorButtonType } from '@/definitions/shared/editor/types'
import { editorBtns } from '@/definitions/shared/editor/data'
import type { DropUpName } from '@/definitions/_layout/dropUps/types'

export default defineComponent({
  components: {
    TmButton,
    TmTooltip,
    TmButtonDropdown,
    TmDropdownItem,
  },
  props: {
    scheduleMenuValue: {
      type: String,
      default: '',
    },
    isTextMessage: {
      type: Boolean,
    },
    isModalView: {
      type: Boolean,
    },
    isCampaign: {
      type: Boolean,
    },
    dropUpName: {
      type: String as PropType<DropUpName>,
      required: true,
    },
  },
  emits: ['schedule-update'],
  setup(props) {
    const isBannerHidden = ref(false)
    const sendNow = ref(!props.scheduleMenuValue)
    const updateButtonDropdownMenu = (sendNowValue: boolean, menuAction:() => void) => {
      sendNow.value = sendNowValue
      nextTick(menuAction)
    }

    const { openModal } = useModals()
    const { openModalPreview } = useComposeOpenModal()
    const openPreviewModal = () => {
      openModal('newMessagePreview', {
        content: 'Hi First name, it’s Charles from Textmagic Edward Tark has asked me to give you a quick call. Please can you let me know when you are free to talk and I’ll phone you? Many thanks.',
      })
    }

    const btnsChatList = computed<EditorButtonType[]>(() => [
      editorBtns.emoji,
      editorBtns.attachment,
      editorBtns.note,
      editorBtns.tags,
      {
        ...editorBtns.preview,
        clickEvent: () => openPreviewModal(),
      },
    ])

    const btnsEmailList: EditorButtonType[] = [
      editorBtns.text,
      editorBtns.emoji,
      editorBtns.attachment,
      editorBtns.assignment,
      editorBtns.tags,
      editorBtns.signature,
      {
        ...editorBtns.preview,
        clickEvent: () => openPreviewModal(),
      },
    ]

    const send = (isCampaign: boolean, dropUpName: string) => {
      if (isCampaign) {
        openModal('campaignPreview', { dropUpName: dropUpName })
      } else {
        openModalPreview(1, false, dropUpName)
      }
    }

    return {
      sendNow,
      updateButtonDropdownMenu,
      openModal,
      openModalPreview,
      isBannerHidden,
      send,
      btnsChatList,
      btnsEmailList,
    }
  },
})
