import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3fa5edd2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialpad-header-top" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex align-center"
}
const _hoisted_3 = { class: "subtle--text lh-20" }
const _hoisted_4 = {
  key: 3,
  class: "subhead-semi-bold-16 white--text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_flag = _resolveComponent("tm-flag")!
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.getState !== 'settings')
      ? (_openBlock(), _createBlock(_component_tm_button, {
          key: 0,
          flat: "",
          color: "transparent",
          "icon-only": "",
          size: "medium",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setState('settings')))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_tm_icon, {
              name: "settings",
              size: "xLarge",
              class: "white--text"
            })
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_tm_button, {
          key: 1,
          class: "dialpad-header-top__back",
          size: "medium",
          "icon-only": "",
          flat: "",
          color: "transparent",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setState('main')))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_tm_icon, {
              name: "chevron_left",
              class: "white--text",
              size: "xLarge"
            })
          ]),
          _: 1
        })),
    (_ctx.getState === 'main' || _ctx.getState === 'hideDialpad')
      ? (_openBlock(), _createBlock(_component_tm_field, {
          key: 2,
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value) = $event)),
          type: "selectSearchCountry",
          class: "dialpad-header-top__select",
          options: _ctx.senderSettingsOptionsFiltered
        }, {
          "selected-item": _withCtx(({ opt }) => [
            (opt.id)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_tm_flag, {
                    country: opt,
                    size: "small",
                    class: "mr-1"
                  }, null, 8, ["country"]),
                  _createElementVNode("div", _hoisted_3, _toDisplayString(opt.name) + " " + _toDisplayString(opt.counter ? `- ${opt.counter}` : ''), 1)
                ]))
              : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: _normalizeClass({
            'light--text': opt.disabled && !opt.subheader
          })
                }, _toDisplayString(opt.label), 3))
          ]),
          _: 1
        }, 8, ["modelValue", "options"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.capitalizeFirstLetter(_ctx.getState)), 1)),
    _createVNode(_component_tm_button, {
      flat: "",
      color: "transparent",
      "icon-only": "",
      size: "medium",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showDialpad = !_ctx.showDialpad))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_icon, {
          name: "close",
          size: "xLarge",
          class: "white--text"
        })
      ]),
      _: 1
    })
  ]))
}