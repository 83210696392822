import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-121292b5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex overflow-hidden h100pr" }
const _hoisted_2 = {
  key: 0,
  class: "dialpad-contacts__alphabet"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dialpad_contacts_item = _resolveComponent("dialpad-contacts-item")!
  const _component_tm_scrollbar = _resolveComponent("tm-scrollbar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dialpad-contacts", {
      'dialpad-contacts--empty': !_ctx.contacts.length,
      'dialpad-contacts--extended': _ctx.extended,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_tm_scrollbar, { onOnScroll: _ctx.handleScroll }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contacts, (contact, i) => {
            return (_openBlock(), _createBlock(_component_dialpad_contacts_item, _mergeProps({ key: i }, contact), null, 16))
          }), 128))
        ]),
        _: 1
      }, 8, ["onOnScroll"]),
      (_ctx.showAlphabet)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alphabet, (letter, i) => {
              return (_openBlock(), _createElementBlock("div", { key: i }, _toDisplayString(letter), 1))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}