import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35f3abfa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dialpad-header-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_field, {
      modelValue: _ctx.fieldValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fieldValue) = $event)),
      placeholder: _ctx.getState === 'contacts' || _ctx.getState === 'history' ? 'Search' : 'Enter number or contact',
      size: "xSmall",
      "left-icon": _ctx.getState === 'contacts' || _ctx.getState === 'history' ? 'search' : '',
      class: _normalizeClass(["dialpad-header-field__input", {
        'dialpad-header-field__input--search': _ctx.getState === 'contacts' || _ctx.getState === 'history',
      }])
    }, null, 8, ["modelValue", "placeholder", "left-icon", "class"]),
    ((_ctx.getState === 'main' || _ctx.getState === 'hideDialpad') || _ctx.fieldValue)
      ? (_openBlock(), _createBlock(_component_tm_icon, {
          key: 0,
          name: "backspace",
          class: _normalizeClass(["pointer ml-2", _ctx.fieldValue ? 'white--text' : 'light--text']),
          size: "xxxLarge",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fieldValue = _ctx.fieldValue.slice(0,-1)))
        }, null, 8, ["class"]))
      : _createCommentVNode("", true)
  ]))
}