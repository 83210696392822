
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmStylizedText from '@/components/shared/TmStylizedText.vue'
import type { NotificationType } from '@/definitions/notifications/types'

export default defineComponent({
  components: {
    TmAvatar,
    TmButton,
    TmStylizedText,
  },
  props: {
    notification: {
      type: Object as PropType<NotificationType>,
    },
  },
  setup(props, context) {
    const hasButtonSlot = !!context.slots['button-slot']
    const hasInfoSlot = !!context.slots['info-slot']

    return {
      hasButtonSlot,
      hasInfoSlot,
    }
  },
})
