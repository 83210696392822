
import { defineComponent, computed } from 'vue'
import { useCalls } from '@/compositions/chats/chats'
import ChatsActiveCallMain from '@/components/pages/chats/activeCall/ChatsActiveCallMain.vue'
import ChatsActiveCallSettings from '@/components/pages/chats/activeCall/ChatsActiveCallSettings.vue'

export default defineComponent({
  components: {
    ChatsActiveCallMain,
    ChatsActiveCallSettings,
  },
  setup() {
    const { currentView } = useCalls()

    const computedComponent = computed(() => {
      return currentView.value === 'main' ? 'chats-active-call-main' : 'chats-active-call-settings'
    })

    return {
      computedComponent,
    }
  },
})
