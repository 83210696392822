
import { defineComponent, computed } from 'vue'
import DialpadContactsItem from '@/components/pages/chats/dialpad/DialpadContactsItem.vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import { getTableData } from '@/services/tableService'
import { checkMatch } from '@/services/string'
import type { PersonType } from '@/definitions/shared/types'
import { useDialpad } from '@/compositions/chats/chats'

type DialpadContactType = {
  person: PersonType;
  phone: string;
  callType?: string;
}

export default defineComponent({
  components: {
    DialpadContactsItem,
    TmScrollbar,
  },
  props: {
    extended: {
      type: Boolean,
    },
    showAlphabet: {
      type: Boolean,
    },
  },
  setup(props) {
    const { fieldValue, getState, setState, getCurrentFilter } = useDialpad()

    const contacts = computed(() => {
      return getTableData(getState.value === 'history' ? 'dialpadCalls' : 'dialpadContacts').filter((e: DialpadContactType) => {
        if (!fieldValue.value && !props.extended) { return false }
        if (getState.value === 'history') {
          return checkMatch(e.person.fullName, fieldValue.value) && (getCurrentFilter.value !== 'missed' || e.callType === 'missed')
        }
        return checkMatch(e.person.fullName, fieldValue.value) || checkMatch(e.phone, fieldValue.value)
      })
    })
    const alphabet = 'abcdefghilklmnopqrstuvwxyz'
    const handleScroll = (position: any) => {
      if (getState.value === 'main' && position.up > 1) {
        setState('hideDialpad')
      }
    }

    return {
      handleScroll,
      alphabet,
      contacts,
    }
  },
})
