
import { defineComponent } from 'vue'
import { useDialpad } from '@/compositions/chats/chats'
import DialpadHeader from '@/components/pages/chats/dialpad/DialpadHeader.vue'
import DialpadNumpad from '@/components/pages/chats/dialpad/DialpadNumpad.vue'
import DialpadFooter from '@/components/pages/chats/dialpad/DialpadFooter.vue'
import DialpadContacts from '@/components/pages/chats/dialpad/DialpadContacts.vue'
import DialpadHistoryFilter from '@/components/pages/chats/dialpad/DialpadHistoryFilter.vue'
import DialpadSettings from '@/components/pages/chats/dialpad/DialpadSettings.vue'

export default defineComponent({
  components: {
    DialpadHistoryFilter,
    DialpadHeader,
    DialpadContacts,
    DialpadNumpad,
    DialpadFooter,
    DialpadSettings,
  },
  setup() {
    const { getState } = useDialpad()

    return {
      getState,
    }
  },
})
