
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmHeader from '@/components/layout/header/Header.vue'
import HeaderProfile from '@/components/layout/header/HeaderProfile.vue'
import HeaderProfileBalance from '@/components/layout/header/headerProfile/HeaderProfileBalance.vue'
import HeaderNotifications from '@/components/layout/header/headerNotifications/HeaderNotifications.vue'
import ChatsActiveCallWidget from '@/components/pages/chats/activeCall/ChatsActiveCallWidget.vue'
import { useCalls } from '@/compositions/chats/chats'

export default defineComponent({
  components: {
    TmButton,
    ChatsActiveCallWidget,
    HeaderProfile,
    HeaderProfileBalance,
    HeaderNotifications,
    TmHeader,
  },
  props: {
    hideBalance: {
      type: Boolean,
    },
  },
  setup() {
    const assignee = {
      avatarColor: 'orange',
      fullName: 'John Doe',
    }
    const { showCall, getActiveCall: activeCall } = useCalls()

    return {
      assignee,
      activeCall,
      showCall,
    }
  },
})
