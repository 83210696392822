import type { Ref, ComputedRef } from 'vue'
import { ref, computed } from 'vue'

type UseSubAccount = () => {
  subAccountLogin: (payload: string) => void
  subAccountLogout: () => void
  subAccount: Ref<string | false>
  subAccountText: ComputedRef<string>
}

const LSName = 'TM_SUB_ACCOUNT'
const getInitialValue = (): string => window.localStorage.getItem(LSName) as string

const subAccount = ref<string>(getInitialValue())

const useSubAccount: UseSubAccount = () => {
  const subAccountLogin = (newValue: string) => {
    subAccount.value = newValue
    window.localStorage.setItem(LSName, newValue)
  }

  const subAccountLogout = () => {
    subAccount.value = ''
    window.localStorage.removeItem(LSName)
  }

  const subAccountText = computed(() => (!subAccount.value)
    ? ''
    : `Logged in as ${subAccount.value} (${subAccount.value.replace(' ', '').toLowerCase()}).`
  )

  return {
    subAccount,
    subAccountText,
    subAccountLogin,
    subAccountLogout,
  }
}

export default useSubAccount
