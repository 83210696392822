
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFlag from '@/components/shared/TmFlag.vue'
import { useDialpad } from '@/compositions/chats/chats'
import { senderSettingsOptions } from '@/definitions/_general/_data/optionsList'
import { capitalizeFirstLetter } from '@/services/string'

export default defineComponent({
  components: {
    TmFlag,
    TmButton,
  },
  setup() {
    const { showDialpad, getState, setState } = useDialpad()
    const senderSettingsOptionsFiltered = senderSettingsOptions.slice(1)
    const value = ref(senderSettingsOptions[3])

    return {
      setState,
      getState,
      capitalizeFirstLetter,
      showDialpad,
      senderSettingsOptionsFiltered,
      value,
    }
  },
})
