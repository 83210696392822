
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import ChatsActiveCallSettingsDevice from '@/components/pages/chats/activeCall/ChatsActiveCallSettingsDevice.vue'
import { useCalls } from '@/compositions/chats/chats'

export default defineComponent({
  components: {
    ChatsActiveCallSettingsDevice,
    TmButton,
  },
  setup() {
    const { currentView } = useCalls()

    return {
      currentView,
    }
  },
})
