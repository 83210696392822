import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_header_notification_item = _resolveComponent("header-notification-item")!

  return (_openBlock(), _createBlock(_component_header_notification_item, { notification: _ctx.notification }, {
    "avatar-slot": _withCtx(() => [
      _createVNode(_component_tm_avatar, {
        url: _ctx.notification.logo.avatar,
        name: _ctx.notification.logo.name,
        color: _ctx.notification.logo.color,
        size: "large"
      }, null, 8, ["url", "name", "color"])
    ]),
    _: 1
  }, 8, ["notification"]))
}