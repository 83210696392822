
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmPriority from '@/components/shared/TmPriority.vue'
import HeaderNotificationItem from '@/components/layout/header/headerNotifications/HeaderNotificationItem.vue'
import type { NotificationType } from '@/definitions/notifications/types'

export default defineComponent({
  components: {
    TmButton,
    TmPriority,
    HeaderNotificationItem,
  },
  props: {
    notification: {
      type: Object as PropType<NotificationType>,
    },
  },
})
