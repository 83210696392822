
import { defineComponent } from 'vue'
import { useCalls } from '@/compositions/chats/chats'
import ChatAvatar from '@/components/shared/chat/ChatAvatar.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    ChatAvatar,
    TmButton,
  },
  setup() {
    const {
      toggleMute,
      getActiveCall: activeCall,
      showCall,
    } = useCalls()

    return {
      toggleMute,
      showCall,
      activeCall,
    }
  },
})
