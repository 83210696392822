
import type { PropType } from 'vue'
import { defineComponent, computed } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import type { ChatSenderType } from '@/definitions/shared/chat/types'
import type { AvatarColorVariant } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmAvatar,
  },
  props: {
    closed: {
      type: Boolean,
    },
    chatSender: {
      type: Object as PropType<ChatSenderType>,
      required: true,
    },
  },
  setup(props) {
    const chatAvatarColor = computed<AvatarColorVariant>(() => {
      if (props.closed) {
        return 'gray'
      }

      return props.chatSender.person ? props.chatSender.person.avatarColor : 'crimson'
    })

    return {
      chatAvatarColor,
    }
  },
})
